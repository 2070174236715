<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/HYXW' }">会议新闻</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/TZ_list' }">重要通知</el-breadcrumb-item>
      <el-breadcrumb-item>通知详情</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="wrap">
      <h1>
        国家卫生健康委办公厅关于印发急诊医学等6个专业
        医疗质量控制指标（2024年版）的通知
      </h1>
      <section>
        <div>
          <img src="@/assets/YLZLKZBZ2024/1.png" alt="">
        </div>
        <div class="h1">
          国家卫生健康委办公厅关于印发急诊医学等6个专业<br />
          医疗质量控制指标（2024年版）的通知
        </div>
        <div class="div_p">
          国卫办医政函〔2024〕150号
        </div>
        <div class="div_p">
          各省、自治区、直辖市及新疆生产建设兵团卫生健康委：
        </div>
        <div class="div_p">
          为进一步加强医疗质量管理，规范临床诊疗行为，促进医疗服务的标准化、同质化，我委组织制修定了急诊医学等6个专业的医疗质量控制指标。现印发给你们，供各级卫生健康行政部门、相关专业质控组织和医疗机构在医疗质量管理与控制工作中使用。2015年印发的《急诊专业医疗质量控制指标（2015年版）》《病理专业医疗质量控制指标（2015年版）》《医院感染管理质量控制指标（2015年版）》同时废止。
        </div>
        <div class="div_p">
          各级各类医疗机构要充分利用相关医疗质量控制指标开展质量管理工作，不断提升医疗质量管理的科学化和精细化水平。各级卫生健康行政部门和相关专业质控中心要加强对本行政区域内医疗机构的培训和指导，推动医疗机构持续改进医疗质量。
        </div>
        <div class="div_p">
          国家卫生健康委办公厅
        </div>
        <div class="div_p">
          2024年4月30日
        </div>
        <div>
          <img src="@/assets/YLZLKZBZ2024/2.png" alt="">
        </div>
        <div class="h1">
          《国家卫生健康委办公厅关于印发急诊医学等6个专业医疗<br />
          质量控制指标（2024年版）的通知》解读
        </div>
        <div class="div_p">
          一、质控指标起草的背景和必要性
        </div>
        <div class="div_p">
          质控指标体系是医疗质量管理与控制体系的重要组成部分，是开展质控工作的重要工具。构建科学、规范的医疗质控指标体系对加强科学化、精细化医疗质量管理，促进医疗质量持续改进具有重要意义。近年来，我委陆续制定印发了部分临床专业质控指标，对促进相关专业的医疗质量管理工作发挥了重要作用。为进一步充实完善医疗质量管理与控制指标体系，我委在前期工作的基础上，组织相关专业国家质控中心制定了急诊医学、脑损伤评价、病理、放射影像、门诊管理、医院感染管理等6个专业医疗质量控制指标2024年版。
        </div>
        <div class="div_p">
          二、主要特点
        </div>
        <div class="div_p">
          本次发布的质控指标分为制定和修订两个类别，一方面，根据质控组织体系建设情况，指导相关专业质控中心组织制定了脑损伤评价、放射影像、门诊管理等3个专业质控指标，扩大指标体系覆盖面，填补专业空白。另一方面，根据行业发展情况，充分汲取既往工作经验，对急诊、病理、医院感染管理3个专业的质控指标进行了修订，使之更加符合当前工作需要。同时，在指标的制定和修订过程中，充分考虑指标的科学性、规范性、可操作性，适合各级卫生健康行政部门、质控组织和各级各类医疗机构在管理工作中应用。后续，我们将根据工作需要和行业发展，进一步扩充和完善相关专业医疗质量控制指标，不断健全和优化质控指标体系。
        </div>
        <div class="div_p">
          三、指标的应用
        </div>
        <div class="div_p">
          各级各类医疗机构要充分认识质控指标的重要意义，准确把握质控指标的定位和内涵，发挥质控指标的工具性作用，结合自身实际情况，利用质控指标开展自我管理，不断提升医疗质量管理的科学化、精细化、规范化水平，保障医疗质量安全。各级卫生健康行政部门和质控组织要根据质控工作需要，结合辖区内实际情况，加强对辖区内医疗机构的培训和指导，规范指标应用，促进医疗机构高质量发展。
        </div>
        <div class="h1">
          放射影像专业医疗质量控制指标（2024年版）
        </div>
        <h2>
          指标一、放射影像检查图像伪影率(RQI-IIA-01)
        </h2>
        <div class="div_p">
          定义：放射影像检查出现不良伪影的例次数占同期放射影像检查总例次数的比例。
        </div>
        <div class="div_p">
          计算公式：
        </div>
        <div>
          <img src="@/assets/YLZLKZBZ2024/3.png" alt="">
        </div>
        <div class="div_p">
          说明：1．本指标中放射影像检查包括电子计算机断层扫描(CT)和磁共振成像(MRI),每1份检查报告为1个检查例次，分别统计其伪影率。
        </div>
        <div class="div_p">
          2．本指标中不良伪影是指在放射影像检查成像过程中产生的与被扫描组织结构无关的异常影像，这些异常影像降低了图像质量，影响对病变的分析诊断。
        </div>
        <div class="div_p">
          意义：反映放射影像检查的图像质量。
        </div>
        <h2>
          指标二、急诊放射影像检查报告2小时完成率
          (RQI-RRC-02)
        </h2>
        <div class="div_p">
          定义：2小时内完成的急诊放射影像检查的报告份数占同期急诊放射影像检查报告总份数的比例。
        </div>
        <div class="div_p">
          计算公式：
        </div>
        <div>
          <img src="@/assets/YLZLKZBZ2024/4.png" alt="">
        </div>
        <div class="div_p">
          说明：1.本指标中急诊放射影像检查是指急诊科开具的X线检查和CT检查。
        </div>
        <div class="div_p">
          2．本指标中放射影像检查报告完成时间是指从患者开始接受X线或CT检查，到检查报告正式出具的时间。
        </div>
        <div class="div_p">
          意义：反映急诊放射影像检查报告出具的及时性。
        </div>
        <h2>
          指标三、放射影像报告书写规范率(RQI-RWS-03)
        </h2>
        <div class="div_p">
          定义：书写规范的放射影像检查报告份数占同期放射影像检查报告总份数的比例。
        </div>
        <div class="div_p">
          计算公式：
        </div>
        <div>
          <img src="@/assets/YLZLKZBZ2024/5.png" alt="">
        </div>
        <div class="div_p">
          说明：本指标中书写规范的放射影像检查报告是指符合以下条件的报告：<br />
          1．有放射科医生签名；<br />
          2．结论（或印象）部分与影像描述部分内容相符；<br />
          3．无明显错误（包括：所查脏器缺如但报告为正常；报告描述检查器官、部位、方位、单位、数据错误；未删除与放射影像报告有歧义的模板文字；患者信息与实际不符或缺失）。
        </div>
        <div class="div_p">
          意义：反映放射影像检查报告书写质量。
        </div>
        <h2>
          指标四、放射影像危急值10分钟内通报完成率
          (RQI-RCV-04)
        </h2>
        <div class="div_p">
          定义：发现放射影像危急值后10分钟内完成通报的病例数占同期放射影像危急值总例数的比例。
        </div>
        <div class="div_p">
          计算公式：
        </div>
        <div>
          <img src="@/assets/YLZLKZBZ2024/6.png" alt="">
        </div>
        <div class="div_p">
          说明：l．本指标中放射影像检查危急值包括以下诊断：急性肺栓塞；急性主动脉夹层(DeBakey
          I型、II型）；急性主动脉瘤破裂；心包填塞；大量液／血／气胸；气管／支气管异物；急性脑梗死；急性脑出血；急性硬膜外／硬膜下出血；急性蛛网膜下腔出血；脑疝；消化道穿孔；腹腔内脏器破裂出血；绞窄性肠梗阻。
        </div>
        <div class="div_p">
          2．本指标中危急值通报完成是指，根据所在医疗机构认可的通报流程，放射科医师完成向临床相关部门或临床医师的危急值通报，且双方都有通报时间、通报内容及通报／接收危急值人员的署名记录。
        </div>
        <div class="div_p">
          意义：反映放射影像危急值通报的及时性。
        </div>
        <h2>
          指标五、增强CT检查静脉对比剂外渗发生率
          (RQI-ICME-05)
        </h2>
        <div class="div_p">
          定义：增强CT检查静脉注射对比剂外渗的例数占同期增强CT检查总例数的比例。
        </div>
        <div class="div_p">
          计算公式：
        </div>
        <div>
          <img src="@/assets/YLZLKZBZ2024/7.png" alt="">
        </div>
        <div class="div_p">
          说明：本指标中对比剂外渗是指行对比剂静脉注射的增强CT检查时及检查后，出现碘对比剂外渗或外漏。
        </div>
        <div class="div_p">
          意义：反映增强CT静脉穿剌质量。
        </div>
        <h2>
          指标六、Pl-RADS分类率(RQI-RCR-06)
        </h2>
        <div class="div_p">
          定义：行PI-RADS分类的前列腺病变磁共振报告份数，占同期前列腺病变磁共振报告总份数的比例。
        </div>
        <div class="div_p">
          计算公式：
        </div>
        <div>
          <img src="@/assets/YLZLKZBZ2024/8.png" alt="">
        </div>
        <div class="div_p">
          意义：反映前列腺磁共振报告规范性。
        </div>
        <h2>
          指标七、Bl-RADS分类率(RQI-RCR-07)
        </h2>
        <div class="div_p">
          定义：行BI-RADS分类的乳腺钼靶报告份数，占同期乳腺病变钼靶报告总份数的比例。
        </div>
        <div class="div_p">
          计算公式：
        </div>
        <div>
          <img src="@/assets/YLZLKZBZ2024/9.png" alt="">
        </div>
        <div class="div_p">
          意义：反映乳腺钼靶报告规范性。
        </div>
      </section>
    </div>


  </div>
</template>

<script>
export default {
  name: "YLZLKZBZ2024"
}
</script>

<style scoped>
.wrap {
  max-width: 677px;
  margin: 40px auto;
  color: rgb(0, 0, 0);
  background: #fff;
  padding: 10px;
  line-height: 2;
}

img {
  vertical-align: middle;
  max-width: 100%;
  width: 655px !important;
  box-sizing: border-box;
  height: auto !important;
  visibility: visible !important;
  margin: 10px 0;
}

h1 {
  font-size: 22px;
  line-height: 1.4;
  margin-bottom: 14px;
  margin-top: 14px;
  text-align: center;
  font-weight: 600;
}

.h1 {
  font-size: 22px;
  line-height: 1.4;
  margin-bottom: 14px;
  margin-top: 14px;
  text-align: center;
  color: cornflowerblue;
}

.div_p {
  text-indent: 2.125em;
  white-space: normal;
  margin: 0px;
  padding: 10px;
  box-sizing: border-box;
  visibility: visible;
}

.div_tit {
  text-align: center;
}

h2 {
  font-size: 16px;
  text-indent: 2.125em;
  white-space: normal;
  margin: 0px;
  padding: 10px;
  box-sizing: border-box;
  visibility: visible;
}
</style>